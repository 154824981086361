import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Alert, listSubheaderClasses } from '@mui/material';
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/client';
import { checkHashHeaderSync } from 'src/utils/checkHashHeader';
import { styled } from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import Subscribe from '../components/Subscribe';
import Spinner from '../components/Spinner';
import Page from '../components/Page';
import { GET_USER_STATUS } from '../graphql';
import getErrorCode from '../utils/getErrorCode';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const Home = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_USER_STATUS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        let msisdn = Cookies.get('msisdn');

        if (!msisdn || typeof msisdn !== 'string' || msisdn === 'undefined') {
          msisdn = '';
          navigate('/auth');
          return;
        }

        // const promise = await checkHashHeaderSync();

        // if (promise === 'no msisdn') {
        //   // Cookies.set('msisdn', "");
        //   // Cookies.set('msisdnHash', "");
        // } else {
        //   localStorage.setItem('msisdn', promise);
        //   localStorage.setItem('msisdnHash', promise);
        //   Cookies.set('msisdn', promise);
        // }

        if (data && data.getUserStatus) {
          const { msisdn, responseCode } = data.getUserStatus;
          console.log('User Status Data:', data.getUserStatus);
          console.log('Data:', data);

          switch (responseCode) {
            case 200:
              localStorage.setItem('msisdn', msisdn);
              localStorage.setItem('msisdnHash', msisdn);
              localStorage.setItem('subscribed', 'true');
              TagManager.dataLayer({
                dataLayer: {
                  event: 'userSubscribed',
                  userId: msisdn,
                },
              });
              setTimeout(() => {
                navigate('/app/games');
              }, 1000);
              break;

            case 100:
            case 400:
              localStorage.setItem('msisdn', msisdn);
              localStorage.setItem('msisdnHash', msisdn);
              localStorage.setItem('subscribed', '');
              localStorage.setItem('newUser', 'newUser');
              navigate('/open/games');
              setTimeout(() => {
                navigate('/subscribe?m=unable');
              }, 300000);
              break;

            case 600:
              localStorage.setItem('subscribed', '');
              navigate('/subscribe?m=unable');
              break;

            case 500:
              localStorage.setItem('subscribed', '');
              navigate('/not-subscribe');
              break;

            default:
              localStorage.setItem('subscribed', '');
              Cookies.set('msisdn', '');
              localStorage.setItem('msisdn', '');
              navigate('/auth');
              break;
          }
        }
      } catch (err) {
        console.error('Error:', err);
        navigate('/he-disable');
      }
    };

    if (!loading && !error) {
      checkUserStatus();
    } else if (error) {
      console.error('GraphQLErrors:', error.graphQLErrors);
      console.error('Error Code:', getErrorCode(error));
      console.error('Error:', error);
      checkUserStatus();
    }
  }, [data, loading, error, navigate]);

  return (
    <Page title="Home | Games Poa App">
      <Container maxWidth="xl">
        <ContentStyle>
          {loading && <Spinner />}
          {error && (
            <>
              {error.graphQLErrors && getErrorCode(error) === 404 ? (
                <Subscribe code={getErrorCode(error)} />
              ) : (
                <Alert severity="error">There was an error. Please try again later.</Alert>
              )}
            </>
          )}
        </ContentStyle>
      </Container>
    </Page>
  );
};

export default Home;
