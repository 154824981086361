import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Alert } from '@mui/material';
import { useQuery } from '@apollo/client';
import Page from '../components/Page';
import Spinner from '../components/Spinner';
import { AppSummaryGames } from '../components/_dashboard/app';
import { GET_USER_STATUS } from '../graphql';

const PremiumGames = () => {
  const navigate = useNavigate();

  const { error, data, loading } = useQuery(GET_USER_STATUS);

  React.useEffect(() => {
    // eslint-disable-next-line no-debugger
    debugger;
    if (error && error.message === 'NO_MSISDN_FOUND') {
      navigate('/he-disable');
    }
    if (error && error.message === '404: Not Found') {
      navigate('/');
    }

    if (data && data.getUserStatus && data.getUserStatus.msisdn) {
      localStorage.setItem('msisdn', data.getUserStatus.msisdn);
      if (data.getUserStatus.responseCode === 300) {
        navigate('/open/games');
      }
    }
  }, [data, error, navigate]);

  return (
    <Page title="Premium Games | ShujaArena">
      <Container maxWidth="xl">
        {error && <Alert severity="error">There was an error. Please try again later.</Alert>}
        {loading && <Spinner />}
        {!error && data?.getUserStatus?.responseCode === 200 && <AppSummaryGames isPremium />}
      </Container>
    </Page>
  );
};

export default PremiumGames;
